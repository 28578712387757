import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    welcome: "Welcome,",
    myr: "MYR :",
    deposit: "Deposit",
    chooseDate: "Choose Date",
    phase: "Phase",
    luckyNumber: "4D Number",
    b: "B",
    s: "S",
    a: "A",
    box: "Box",
    ibox: "IBox",
    bet: "BET",
    loading: "Loading",
    numberMin4: "Number Mininum 4",
    numberRequired: "Number Required",
    companyRequired: "Company Required",
    noRecordFound: "No Record Found",
    chooseCompany: "Choose Company",
    betType: "Bet Type",
    estimatePrice: "Estimate Price",
    add: "Add",
    sumMin1: "Sum of Big, Small, 4A must be at least 1",
    chooseSeason: "Choose Season",
    basicLottery: "Basic",
    specialLottery: "Special",
    season: "Season",
    selectCompany: "Please Select Company",
    fail: "Fail",
    inputNumber: "Please Input 4 Lucky Number",
    inputBAS: "Please Input Any Amount To B, S or 4A",
    no: "No.",
    number: "Number",
    company: "Company",
    estimateTotal: "Estimate Total :",
    alert: "Alert",
    confirmRemoveNumber: "Do you confirm to remove this number?",
    confirm: "Confirm",
    refreshIn: "Refreshing",
    firstPrize: "1st Prize",
    secondPrize: "2nd Prize",
    thirdPrize: "3rd Prize",
    prize: "Prize",
    big: "Big",
    small: "Small",
    basic: "Basic",
    special: "Special",
    consolation: "Consolation",
    wallet: "Wallet",
    history: "History",
    winning: "Winning",
    drawDate: "Draw Date",
    chip: "Chip",
    withdraw: "Withdraw",
    paymentMethod: "Payment Method",
    paymentGateway: "Payment Gateway",
    atm: "ATM",
    receipt: "Receipt",
    depositAccount: "Deposit Account",
    bankName: "Bank Name",
    accountName: "Account Name",
    accountNumber: "Account Number",
    amount: "Amount",
    receiptRequired: "Receipt Required",
    submit: "Submit",
    fullName: "Full Name",
    fullNameRequired: "Full Name Required",
    accountNumRequired: "Account Number Required",
    addBank: "Add Bank",
    accountNumRequired: "Account Number Required",
    available: "Available",
    pending: "Pending",
    all: "All",
    addBankDescription: "Please bind a bank card first to withdrawal",
    amountRequired: "Amount Required",
    dateTime: "DateTime",
    action: "Action",
    remark: "Remark",
    cash: "Cash",
    username: "Username",
    password: "Password",
    email: "Email",
    refferalLink: "Refferal Link",
    registerDate: "Register Date",
    language: "Language",
    login: "Log In",
    forgetpassword: "Forget Password",
    usernameRequired: "Username Required",
    passwordRequired: "Password Required",
    rememberMe: "Remember Me",
    dontHaveAccount: "Didn't Have Account?",
    signUp: "Sign Up",
    logout: "Logout",
    phoneNo: "Phone No.",
    gmail: "Gmail",
    verificationCode: "Verification Code",
    phoneNoRequired: "Phone No. Required",
    verifyCodeRequired: "Verify Code Required",
    requestOTP: "Request OTP",
    orRegisterWith: "Or Register With",
    registerWithPhone: "Register With Phone",
    chooseRegisterMethod: "Choose Register Method",
    registerWithEmail: "Register With Email",
    refferalCode: "Refferal Code",
    alreadyHaveAccount: "Already Have Account?",
    register: "Register",
    requestOTP: "Request OTP",
    phoneNoRequired: "Phone No. Required",
    otpHasSended:
      "Thank you for providing your phone number. We've just sent a one-time password (OTP) to the number",
    otpEmailHasSended:
      "Thank you for providing your email. We've just sent a one-time password (OTP) to the email",
    otpRequired: "OTP Required",
    currency: "Currency",
    incorrectVerificationCode: "Incorrect Verification Code",
    telno: "Tel No.",
    invalidEmailFormat: "Invalid Email Format",
    emailRequired: "Email Required",
    backErrorDescription:
      "Please note that if you decide to go back, you will be required to repeat all the step again. Are you sure you want to proceed with it?",
    error: "Error",
    cancel: "Cancel",
    success: "Success",
    registerSuccess: "Register Successfully",
    copySuccessfully: "Copy Successfully",
    editPassword: "Edit Password",
    confirmPassword: "Confirm Password",
    oldPasswordRequired: "Old Password Required",
    newPassword: "New Password",
    oldPassword: "Old Password",
    newPasswordRequired: "New Password Required",
    confirmPasswordRequired: "Confirm Password Required",
    changePasswordSuccess: "Change Password Successfully",
    phoneAuth: "Phone Auth",
    verifyCode: "Verify Code",
    auth: "Authorization",
    sessionLost: "Session Lost. Please login again",
    selected: "Selected",
    depositAccountList: "Deposit Account List",
    otherBankName: "Other Bank Name",
    otherBankNameRequired: "Other Bank Name Required",
    addBankSuccessfully: "Add Bank Successfully",
    deleteBankSuccessfully: "Delete Bank Successfully",
    confirmRemoveBank: "Confirm Remove Bank?",
    openNewTab: "Redirect To Online Banking",
    gateway: "Gateway",
    chooseABank: "Choose A Bank",
    days: "Days",
    depositRequestSendSuccessfully: "Deposit Request Send Successfully",
    withdrawRequestSendSuccessfully: "Withdraw Request Send Successfully",
    type: "Type",
    historyDetails: "History Details",
    requestDate: "Request Date",
    reportType: "Report Type",
    status: "Status",
    detail: "Detail",
    edit: "Edit",
    close: "Close",
    manualTopupCancelSuccess: "Manual Topup Cancel Successfully",
    withdrawCancelSuccess: "Withdraw Cancel Successfully",
    confirmEditToCancel: "Confirm Edit To Cancel?",
    editStatus: "Edit Status",
    noDetailsAvailable: "No Details Available",
    confirmSubmitNumber: "Confirm Submit Number?",
    purchaseSuccessfully: "Purchase Successfully",
    advance: "Advance",
    totalBet: "Total Bet",
    totalWin: "Total Win",
    reorder: "Reorder",
    void: "Void",
    confirmVoidSlip: "Are you sure you want to void this slip?",
    confirmReorder: "Are you sure you want to reorder?",
    strikeBig: "Strike Big",
    strikeSmall: "Strike Small",
    strike4a: "Strike 4A",
    reorderSuccessfully: "Reorder Successfully",
    voidSuccessfully: "Void Successfully",
    "4a": "4A",
    date: "Date",
    slipHistory: "Slip History",
    progress: "Progress",
    next: "Next",
    addNumber: "Please Add at least 1 number",
    remove: "Remove",
    onhold: "On Hold",
    holdAmount: "Hold Amount",
    seasonLeft: "Season Left",
    onHoldBalance: "On Hold Balance",
    redirectToAgent: "Redirect To Agent",
    minBet: "Min Bet 0.50",
    switchToHL:
      "Please note that if you decide switch to special, your data will be clear. Are you sure you want to proceed with it?",
    switchToMKT:
      "Please note that if you decide switch to basic, your data will be clear. Are you sure you want to proceed with it?",
    InsufficientBal: `Insufficient Balance, do you wan't proceed to deposit?`,
    openCustomerService: "Redirect To Customer Service",
    directMemberCount: "Direct Member Count",
    commissionNrebate: "Commission & Rebate",
    personal: "Personal",
    firstGen: "1st Gen",
    secondGen: "2nd Gen",
    thirdGen: "3rd Gen",
    commission: "Commission",
    personalrebate: "Personal Rebate",
    directcommission: "Direct Commission",
    groupcommission: "Group Commission",
    commissionGrowUp: "Commission Grow Up",
    groupGrow: "Group Grow",
    directGrow: "Direct Grow",
    groupStructure: "Group Structure",
    nextComing: "Next Coming",
    downline: "Downline",
    english: "English",
    mandarin: "Mandarin",
    choose_language: "Choose Language",
    invitationLink: "Invitation Link",
    downloadapp: "Download App",
  },

  [LOCALES.CHINESE]: {
    welcome: "欢迎,",
    myr: "MYR :",
    deposit: "存款",
    chooseDate: "选择日期",
    phase: "期",
    luckyNumber: "4D Number",
    b: "大",
    s: "小",
    a: "A",
    box: "Box",
    ibox: "IBox",
    bet: "投注",
    loading: "加载中",
    numberMin4: "号码最少为4位",
    numberRequired: "号码是必填项",
    companyRequired: "公司是必选项",
    noRecordFound: "未找到记录",
    chooseCompany: "选择公司",
    betType: "投注类型",
    estimatePrice: "预计价格",
    add: "添加",
    sumMin1: "大、小、4A的总和至少为1",
    chooseSeason: "选择期限",
    basicLottery: "普通",
    specialLottery: "特别",
    season: "期限",
    selectCompany: "请选择公司",
    fail: "失败",
    inputNumber: "请输入4个幸运号码",
    inputBAS: "请输入大、小或4A的任意金额",
    no: "编号",
    number: "号码",
    company: "公司",
    estimateTotal: "预计总计：",
    alert: "警告",
    confirmRemoveNumber: "您确定要删除这个号码吗？",
    confirm: "确认",
    refreshIn: "刷新中",
    firstPrize: "头奖",
    secondPrize: "二奖",
    thirdPrize: "三奖",
    prize: "奖品",
    big: "大",
    small: "小",
    basic: "普通",
    special: "特别奖",
    consolation: "安慰奖",
    wallet: "钱包",
    history: "历史",
    winning: "中奖",
    drawDate: "开奖日期",
    chip: "筹码",
    withdraw: "提现",
    paymentMethod: "支付方式",
    paymentGateway: "支付网关",
    atm: "ATM",
    receipt: "收据",
    depositAccount: "存款账户",
    bankName: "银行名称",
    accountName: "账户名称",
    accountNumber: "账户号码",
    amount: "金额",
    receiptRequired: "需要收据",
    submit: "提交",
    fullName: "全名",
    fullNameRequired: "全名是必填项",
    accountNumRequired: "账户号码是必填项",
    addBank: "添加银行",
    available: "可用的",
    pending: "待处理",
    all: "全部",
    addBankDescription: "请先绑定银行卡以提现",
    amountRequired: "金额是必填项",
    dateTime: "日期时间",
    action: "操作",
    remark: "备注",
    cash: "现金",
    username: "用户名",
    password: "密码",
    email: "电子邮件",
    refferalLink: "推荐链接",
    registerDate: "注册日期",
    language: "语言",
    login: "登录",
    forgetpassword: "忘记密码",
    usernameRequired: "用户名是必填项",
    passwordRequired: "密码是必填项",
    rememberMe: "记住我",
    dontHaveAccount: "没有账户？",
    signUp: "注册",
    logout: "退出",
    phoneNo: "电话号码",
    gmail: "Gmail",
    verificationCode: "验证码",
    phoneNoRequired: "电话号码是必填项",
    verifyCodeRequired: "验证码是必填项",
    requestOTP: "请求OTP",
    orRegisterWith: "或者通过以下方式注册",
    registerWithPhone: "通过手机注册",
    chooseRegisterMethod: "选择注册方式",
    registerWithEmail: "通过电子邮件注册",
    refferalCode: "推荐码",
    alreadyHaveAccount: "已有账户？",
    register: "注册",
    otpHasSended: "感谢您提供您的电话号码。我们已向您的号码发送一次性密码(OTP)",
    otpEmailHasSended:
      "感谢您提供您的电子邮件。我们已向您的电子邮件发送一次性密码(OTP)",
    otpRequired: "OTP是必填项",
    currency: "货币",
    incorrectVerificationCode: "验证码错误",
    telno: "电话号码",
    invalidEmailFormat: "电子邮件格式无效",
    emailRequired: "电子邮件是必填项",
    backErrorDescription:
      "请注意，如果您选择返回，您将需要重新执行所有步骤。您确定要继续吗？",
    error: "错误",
    cancel: "取消",
    success: "成功",
    registerSuccess: "注册成功",
    copySuccessfully: "复制成功",
    editPassword: "修改密码",
    confirmPassword: "确认密码",
    oldPasswordRequired: "旧密码是必填项",
    newPassword: "新密码",
    oldPassword: "旧密码",
    newPasswordRequired: "新密码是必填项",
    confirmPasswordRequired: "确认密码是必填项",
    changePasswordSuccess: "密码修改成功",
    phoneAuth: "手机验证",
    verifyCode: "验证码",
    auth: "授权",
    sessionLost: "会话丢失。请重新登录",
    selected: "已选择",
    depositAccountList: "存款账户列表",
    otherBankName: "其他银行名称",
    otherBankNameRequired: "其他银行名称是必填项",
    addBankSuccessfully: "添加银行成功",
    deleteBankSuccessfully: "删除银行成功",
    confirmRemoveBank: "确认删除银行？",
    openNewTab: "重定向到网上银行",
    gateway: "网关",
    chooseABank: "选择银行",
    days: "天数",
    depositRequestSendSuccessfully: "存款请求发送成功",
    withdrawRequestSendSuccessfully: "提现请求发送成功",
    type: "类型",
    historyDetails: "历史详情",
    requestDate: "请求日期",
    reportType: "报告类型",
    status: "状态",
    detail: "详情",
    edit: "编辑",
    close: "关闭",
    manualTopupCancelSuccess: "手动充值取消成功",
    withdrawCancelSuccess: "提现取消成功",
    confirmEditToCancel: "确认编辑取消？",
    editStatus: "编辑状态",
    noDetailsAvailable: "没有可用的详情",
    confirmSubmitNumber: "确认提交号码？",
    purchaseSuccessfully: "购买成功",
    advance: "高级",
    totalBet: "总投注",
    totalWin: "总中奖",
    reorder: "重新排序",
    void: "作废",
    confirmVoidSlip: "您确定要作废这张单据吗？",
    confirmReorder: "您确定要重新排序吗？",
    strikeBig: "打大",
    strikeSmall: "打小",
    strike4a: "打4A",
    reorderSuccessfully: "重新排序成功",
    voidSuccessfully: "作废成功",
    "4a": "4A",
    date: "日期",
    slipHistory: "单据历史",
    progress: "进展",
    next: "下一步",
    addNumber: "请至少添加1个号码",
    remove: "删除",
    onhold: "等待处理",
    holdAmount: "冻结金额",
    seasonLeft: "剩余季节",
    onHoldBalance: "冻结余额",
    redirectToAgent: "重定向到代理",
    minBet: "最小投注0.50",
    switchToHL:
      "请注意，如果您决定切换到特别，您的数据将被清除。您确定要继续吗？",
    switchToMKT:
      "请注意，如果您决定切换到基本，您的数据将被清除。您确定要继续吗？",
    InsufficientBal: `余额不足，您是否想继续进行存款？`,
    openCustomerService: "重定向到客户服务",
    directMemberCount: "直接会员数量",
    commissionNrebate: "佣金与回扣",
    personal: "个人",
    firstGen: "第一代",
    secondGen: "第二代",
    thirdGen: "第三代",
    commission: "佣金",
    personalrebate: "个人回扣",
    directcommission: "直接佣金",
    groupcommission: "组佣金",
    commissionGrowUp: "佣金增长",
    groupGrow: "组增长",
    directGrow: "直接增长",
    groupStructure: "组结构",
    nextComing: "下一个即将到来",
    downline: "下线",
    english: "英语",
    mandarin: "中文",
    choose_language: "选择语言",
    invitationLink: "邀请链接",
    downloadapp: "下载App",
  },
  [LOCALES.MALAY]: {},
  [LOCALES.INDO]: {},
};
