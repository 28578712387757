import { useIntl } from "react-intl";
import { useState, useEffect } from "react";
import SweetAlert2 from "react-sweetalert2";
import { LoginTab } from "../enum/LoginTab";
import { RegisterMethodTab } from "./loginTab/RegisterMethodTab";
import { RegisterTab } from "./loginTab/RegisterTab";
import { LoginFormTab } from "./loginTab/LoginFormTab";
import { ForgetPasswordTab } from "./loginTab/ForgotPasswordTab";
import { useAuth } from "./AuthInit";
import { LoginPhoneAuthTab } from "./loginTab/LoginPhoneAuthTab";
import {
  GoogleRegisterTab,
  RegisterEmailTab,
} from "./loginTab/GoogleRegisterTab";
import { fetchLogoBackground } from "../api/loginApi";
import { FiArrowLeft } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";

export const LoginRegisterDialog = ({ initialTab }) => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const [bannerLists, setBannerLists] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const [loginTab, setLoginTab] = useState(
    initialTab ? initialTab : LoginTab.LOGIN
  );
  const [tempAuth, setTempAuth] = useState();
  const { logout } = useAuth();
  const { credential } = useParams();
  const getLogoBackground = () => {
    // fetchLogoBackground({ language: intl.locale }).then((resp) => {
    //   if (resp && resp.bannerLists) {
    //     setBannerLists(resp.bannerLists);
    //   }
    // });
  };

  // const UserData = [
  //   {
  //     code: "0",
  //     message: "",
  //     agentid: "AGdemoagentid223",
  //     loginid: "LG1724728736769",
  //     masterid: "ABC",
  //     projectid: "projectdemolocal1",
  //     projectname: "Project Demo 1 (Local)",
  //     currencycode: "MYR",
  //     username: "agent",
  //     role: "main",
  //     weburl: "https://admin-bo.es51.com",
  //   },
  // ];

  useEffect(() => {
    // localStorage.setItem("UserData", JSON.stringify(UserData));
    getLogoBackground();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBannerIndex((prevIndex) =>
        prevIndex === bannerLists.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => clearInterval(intervalId);
  }, [bannerLists]);

  const handleBack = () => {
    const backTab =
      loginTab === LoginTab.REGISTER_PART_1 ||
      loginTab === LoginTab.GOOGLE_REGISTER
        ? LoginTab.REGISTER_OPTION
        : loginTab === LoginTab.REGISTER_PART_2
        ? LoginTab.REGISTER_PART_1
        : loginTab === LoginTab.FORGOT_PASSWORD_PART_2
        ? LoginTab.FORGOT_PASSWORD_PART_1
        : loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2
        ? LoginTab.LOGIN_PHONE_AUTH_STEP_1
        : LoginTab.LOGIN;

    if (
      loginTab === LoginTab.REGISTER_PART_3 ||
      loginTab === LoginTab.FORGOT_PASSWORD_PART_3 ||
      loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2 ||
      loginTab === LoginTab.GOOGLE_REGISTER_PART2
    ) {
      setSwalProps({
        show: true,
        icon: "info",
        title: intl.formatMessage({ id: "alert" }),
        text: intl.formatMessage({
          id: "backErrorDescription",
        }),
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: intl.formatMessage({ id: "confirm" }),
        denyButtonText: intl.formatMessage({ id: "cancel" }),
        onConfirm: () => {
          logout();
          setLoginTab(
            loginTab === LoginTab.REGISTER_PART_3 ||
              LoginTab.GOOGLE_REGISTER_PART2
              ? LoginTab.REGISTER_OPTION
              : LoginTab.LOGIN
          );
        },
      });
    } else {
      setLoginTab(backTab);
    }
  };

  return (
    <div className="login-body" style={{ height: "100vh" }}>
      <SweetAlert2
        {...swalProps}
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
      />
      <div>
        <div
          style={{
            height: "5%",
            width: "100%",
            display: "flex",
            paddingTop: "5px",
            alignItems: "flex-start",
          }}
        >
          {loginTab !== LoginTab.LOGIN ? (
            <div style={{ marginLeft: "20px", marginTop: "20px" }}>
              <button
                onClick={handleBack}
                style={{ background: "none", border: "none" }}
              >
                <FiArrowLeft size={25} style={{ color: "white" }} />
              </button>
            </div>
          ) : (
            <div style={{ marginLeft: "20px", marginTop: "20px" }}>
              <button
                onClick={handleBack}
                style={{
                  background: "none",
                  border: "none",
                  color: "transparent",
                }}
                disabled={loginTab === LoginTab.LOGIN}
              >
                <FiArrowLeft size={25} />
              </button>
            </div>
          )}
        </div>
        <div
        // style={{ height: "30%", width: "100%", position: "relative" }}
        >
          <img
            src={toAbsoluteUrl("/image/logo/logo.png")}
            className="w-100 h-100 object-fit-cover"
          />
          {/* {bannerLists.length > 0 && (
            <>
              <img
                src={bannerLists[currentBannerIndex].imageurl}
                alt={`Banner ${currentBannerIndex + 1}`}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
              <img
                src={process.env.PUBLIC_URL + "/image/logo.png"}
                alt="logo"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "200px",
                  zIndex: 1, // Ensure the logo is above the banner image
                }}
              />
            </>
          )} */}
        </div>

        <div className="d-flex justify-content-center">
          <div className="col-10">
            {loginTab === LoginTab.LOGIN ? (
              <LoginFormTab
                setSwalProps={setSwalProps}
                setLoginTab={setLoginTab}
                setTempAuth={setTempAuth}
                credential={credential}
              />
            ) : loginTab === LoginTab.REGISTER_OPTION ? (
              <RegisterMethodTab setLoginTab={setLoginTab} />
            ) : loginTab === LoginTab.REGISTER_PART_1 ||
              loginTab === LoginTab.REGISTER_PART_2 ||
              loginTab === LoginTab.REGISTER_PART_3 ? (
              <RegisterTab
                setLoginTab={setLoginTab}
                setSwalProps={setSwalProps}
                loginTab={loginTab}
              />
            ) : loginTab === LoginTab.FORGOT_PASSWORD_PART_1 ||
              loginTab === LoginTab.FORGOT_PASSWORD_PART_2 ||
              loginTab === LoginTab.FORGOT_PASSWORD_PART_3 ? (
              <ForgetPasswordTab
                setLoginTab={setLoginTab}
                setSwalProps={setSwalProps}
                loginTab={loginTab}
              />
            ) : loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_1 ||
              loginTab === LoginTab.LOGIN_PHONE_AUTH_STEP_2 ? (
              <LoginPhoneAuthTab
                setLoginTab={setLoginTab}
                setSwalProps={setSwalProps}
                loginTab={loginTab}
                tempAuth={tempAuth}
              />
            ) : loginTab === LoginTab.GOOGLE_REGISTER ||
              loginTab === LoginTab.GOOGLE_REGISTER_PART2 ? (
              <RegisterEmailTab
                setLoginTab={setLoginTab}
                setSwalProps={setSwalProps}
                loginTab={loginTab}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
