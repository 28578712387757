import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { useAuth } from "../modules/auth/components/AuthInit";
import { AuthWrapper } from "../pages/auth/AuthWrapper";

const { PUBLIC_URL } = process.env;

const AppRoutes = () => {
  const { auth } = useAuth();
  const queryParameters = new URLSearchParams(window.location.search);
  const platform = queryParameters.get("platform");
  if (platform && platform.trim() !== "") {
    sessionStorage.setItem("platform", platform);
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        {auth ? (
          <>
            <Route path="/*" element={<PrivateRoutes />} />
            <Route index element={<Navigate to="/home" />} />
          </>
        ) : (
          <>
            <Route path="/*" element={<AuthWrapper />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
