import React, { useEffect, useRef, useState } from "react";
import { DateRange } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./DateRangeComp.css";

const DateRangeComp = ({ initialRange, onDateChange }) => {
  const [range, setRange] = useState(initialRange);
  const [open, setOpen] = useState(false);
  const [direction, setDirection] = useState("horizontal");
  const refOne = useRef(null);

  useEffect(() => {
    const updateDirection = () => {
      if (window.innerWidth <= 768) {
        setDirection("vertical");
      } else {
        setDirection("horizontal");
      }
    };
    window.addEventListener("resize", updateDirection);
    updateDirection();

    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);

    return () => {
      document.removeEventListener("keydown", hideOnEscape, true);
      document.removeEventListener("click", hideOnClickOutside, true);
      window.removeEventListener("resize", updateDirection);
    };
  }, []);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (
      refOne.current &&
      !refOne.current.contains(e.target) &&
      !e.target.classList.contains("inputBox")
    ) {
      setOpen(false);
    }
  };

  const handleRangeChange = (item) => {
    setRange([item.selection]);
    if (item.selection.startDate && item.selection.endDate) {
      onDateChange(item.selection.startDate, item.selection.endDate);
    }
  };

  return (
    <div className="calendarWrap">
      <div className="w-100">
        <input
          value={`${format(range[0].startDate, "yyyy-MM-dd")} to ${format(
            range[0].endDate,
            "yyyy-MM-dd"
          )}`}
          readOnly
          className="inputBox w-100 text-center"
          style={{ borderRadius: "5px" }}
          onClick={() => {
            console.log(open);
            setOpen((prev) => !prev);
          }}
        />
        <div ref={refOne}>
          {open && (
            <DateRange
              preventSnapRefocus={true}
              showSelectionPreview={true}
              onChange={handleRangeChange}
              editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              ranges={range}
              months={2}
              direction={direction}
              className="calendarElement"
              maxDate={new Date()}
              calendarFocus="backwards"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DateRangeComp;
