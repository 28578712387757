import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./ProfileCRUD";
import { profileSlice } from "./ProfileSlice";

const { actions } = profileSlice;

export const updatePassword = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchUpdatePassword(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.updatePasswordFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: updatePasswordFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const redirectToAgent = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchRedirectAgent(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.redirectToAgentFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: redirectToAgentFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const updateLanguage = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchUpdateLanguage(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.updateLanguageFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: updateLanguageFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
