import axios from "axios";

const baseUrl = "http://34.87.51.38/w188_api";

export const LOGIN_URL = `${baseUrl}/login`;

export const getCurrentCountryCode = (ipAddress) => {
  return axios
    .get(`https://ipapi.co/${ipAddress}/json/`, {})
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const login = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const fetchIPAddress = () => {
  return axios
    .get(`https://m.member.lotto4896.com/api/clientIP.php`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const fetchVerificationCode = () => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/General1.ashx`,
      data: { action: "verificationcode" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const getOTP = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: { action: "memberrequestotp_telno", ...queryParams },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const fetchLogoBackground = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/MemberMobile1/MbLoginRegisterPageUI`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const fetchCurrencyList = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member1/CurrencyList`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//start : register

export const register = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: { action: "memberrequestotp_email", ...queryParams },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const registerStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: { action: "membersubmitotp_register", ...queryParams },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const registerStep3 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: { action: "memberregister_final", ...queryParams },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : register

//start : phone authentication

export const phoneAuthStep1 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: { action: "memberactiverequestotp_phone", ...queryParams },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const emailAuthStep1 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: { action: "memberactiverequestotp_email", ...queryParams },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const phoneAuthStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: { action: "membersubmitotp_active", ...queryParams },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : phone authentication

//start : forgot password

export const forgotPasswordStep1 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const forgotPasswordStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member01.ashx`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const forgotPasswordStep3 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member1/ResetPasswordOtpPhone`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : forgot password
//start : gmailforgot password

export const gmailForgotPasswordStep1 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member2/ForgetPasswordEmail`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const gmailForgotPasswordStep2 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member2/ForgetPasswordEmailCheckOtp`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const gmailForgotPasswordStep3 = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member2/ResetPasswordOtpEmail`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : gmail forgot password
//start : google authentication

export const googleRegister = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member2/SocialMediaRegister`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

export const googleLogin = (queryParams) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/Member2/SocialMediaLogin`,
      data: queryParams,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};

//end : google authentication

export const fetchLoginSlide = (language) => {
  return axios
    .post(`${window.location.protocol}//m.member.lotto4896.com/api/index.php`, {
      url: `${baseUrl}/MemberPc1/PcLoginRegisterPageUI`,
      data: { language },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const fetchPromoUrl = (queryParams) => {
  return axios
    .post(
      `${window.location.protocol}//m.member.lotto4896.com/api/getApi.php`,
      {
        url: `${baseUrl}/Member8/PromoDialogUrl?${queryParams}`,
        data: {},
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
};
export const getUserData = () => {
  const data = localStorage.getItem("UserData");
  if (!data) {
    return;
  }

  try {
    const auth = JSON.parse(data);
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};
