import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/ProfileAction";
import Skeleton from "react-loading-skeleton";
import { CopyToClipboard, toAbsoluteUrl } from "../../../utils/CommonHelper";
import { FiArrowLeft, FiChevronRight } from "react-icons/fi";
import { Link } from "react-router-dom";
import { EditPasswordDialog } from "./editPassword/EditPasswordDialog";
import SweetAlert2 from "react-sweetalert2";
// import { ChangeRegionDialog } from "./changeRegion/ChangeRegionDialog";
import { useAuth } from "../../auth/components/AuthInit";
import QRCode from "react-qr-code";
import CryptoJS from "crypto-js";
import { Button, Drawer } from "@mui/material";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";

export const Personal = () => {
  const intl = useIntl();
  const { logout } = useAuth();
  const dispatch = useDispatch();
  const profileState = useSelector(({ profile }) => profile, shallowEqual);
  const [swalProps, setSwalProps] = useState({});
  const { auth, saveAuth } = useAuth();
  const [openLanguage, setOpenLanguage] = useState(false);
  const language = localStorage.getItem("locale") || "en";

  const handleIconClick = () => {
    const queryParams = {
      action: "redirecttoagent",
    };
    dispatch(actions.redirectToAgent(queryParams)).then((resp) => {
      const { username, password } = resp.data;
      const encrypted = CryptoJS.AES.encrypt(
        JSON.stringify({ username: username, password: password }),
        "lott4896#!@lott4896"
      ).toString();
      const encryptedCredential = encodeURIComponent(btoa(encrypted));

      window.location.href = `https://agent.lotto4896.com/auth/${encryptedCredential}`;
    });
  };
  const handleLogout = async () => {
    if (sessionStorage.getItem("platform") === "mobile") {
      console.log("press:logout");
      localStorage.removeItem("UserData");
      sessionStorage.clear();
    } else {
      await logout();
    }
  };

  const _showLanguage = () => {
    setOpenLanguage(true);
  };

  const handleCloseLanguage = () => {
    setOpenLanguage(false);
  };

  const handleLanguageChange = (language) => {
    const queryParams = {
      action: "updatelanguage",
      language: language,
    };
    dispatch(actions.updateLanguage(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        if (sessionStorage.getItem("platform") === "mobile") {
          console.log("update:language");
          localStorage.setItem("locale", language);
          // window.location.reload();
        } else {
          localStorage.setItem("locale", language);
          window.location.reload();
        }
      }
    });
  };
  return (
    <div>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        backdrop={false}
        {...swalProps}
        customClass="custom-swal"
      />

      <EditPasswordDialog setSwalProps={setSwalProps} />

      <div className="my-3 d-flex justify-content-center">
        <QRCode
          value={`https://m.member.lotto4896.com/register/${auth.memberid}`}
          size={200}
          bgColor="#1c1e23"
          fgColor="#fff"
        />
      </div>
      <div className="ms-2 ">
        <div className="border w-100 rounded">
          <div className="d-flex mx-1 mt-2">
            <div className="w-50 ">
              {intl.formatMessage({ id: "username" })}
            </div>
            <div className="w-50">{intl.formatMessage({ id: "password" })}</div>
          </div>
          <div className="d-flex mx-1 mb-2">
            <div className="w-50 ">{auth.username}</div>
            <div className="w-50">
              {"*******"}&nbsp;
              <i
                className="bi bi-pencil-square"
                data-bs-toggle="modal"
                data-bs-target="#passwordModal"
              />
            </div>
          </div>
          {auth.email === "" ? (
            <>
              <div className="d-flex mx-1">
                <div className="w-100 ">
                  {intl.formatMessage({ id: "telno" })}
                </div>
              </div>
              <div className="d-flex mx-1 mb-2">
                <div className="w-100 ">{"+" + auth.telno}</div>
              </div>
            </>
          ) : (
            <>
              <div className="d-flex mx-1 ">
                <div className="w-100 ">
                  {intl.formatMessage({ id: "email" })}
                </div>
              </div>
              <div className="d-flex mx-1 mb-2">
                <div className="w-100 ">{auth.email}</div>
              </div>
            </>
          )}
          <div className="d-flex mx-1">
            <div className="w-100 ">
              {intl.formatMessage({ id: "invitationLink" })}
            </div>
          </div>
          <div className="d-flex mx-1 mb-2">
            <div className="w-100 ">
              <marquee
                behavior="scroll"
                direction="left"
                style={{ width: "85%" }}
              >
                {"https://m.member.lotto4896.com/register/" + auth.memberid}
              </marquee>
              &nbsp;
              <i
                className="bi bi-copy mx-1 cursor-pointer"
                onClick={() => {
                  if (sessionStorage.getItem("platform") === "mobile") {
                    console.log(
                      `clipboard: https://m.member.lotto4896.com/register/${auth.memberid}`
                    );
                    CopyToClipboard(
                      `https://m.member.lotto4896.com/register/${auth.memberid}`,
                      setSwalProps,
                      intl
                    );
                  } else {
                    CopyToClipboard(
                      `https://m.member.lotto4896.com/register/${auth.memberid}`,
                      setSwalProps,
                      intl
                    );
                  }
                }}
              />
            </div>
          </div>
          <div className="d-flex mx-1">
            <div className="w-100">
              {intl.formatMessage({ id: "language" })}
            </div>
          </div>

          <div
            onClick={_showLanguage}
            className="d-flex mx-1 mb-2 justify-content-between"
          >
            <div className="d-flex">
              <div className="me-2">
                {language === "en" ? (
                  <img
                    src={toAbsoluteUrl("image/homePage/en.png")}
                    style={{ width: "25px", height: "25px" }}
                    alt="English"
                  />
                ) : (
                  <img
                    src={toAbsoluteUrl("image/homePage/cn.png")}
                    style={{ width: "25px", height: "25px" }}
                    alt="Mandarin"
                  />
                )}
              </div>

              <div>
                {language === "en"
                  ? intl.formatMessage({ id: "english" })
                  : intl.formatMessage({ id: "mandarin" })}
              </div>
            </div>
            <div>
              <FiChevronRight />
            </div>
          </div>
        </div>

        {auth.isagent === "true" && (
          <div className="my-4 ">
            <button
              className="btn btn-prim w-100 "
              onClick={auth.isagent === "true" ? () => handleIconClick() : null}
            >
              {intl.formatMessage({ id: "redirectToAgent" })}
            </button>
          </div>
        )}

        <div className="my-5">
          <div
            className="d-flex justify-content-center w-100"
            style={{ textAlign: "center" }}
          >
            <button
              onClick={handleLogout}
              style={{
                color: "red",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              {intl.formatMessage({ id: "logout" })}
            </button>
          </div>
        </div>
      </div>
      <Drawer
        anchor="bottom"
        open={openLanguage}
        onClose={handleCloseLanguage}
        style={{ minHeight: "40vh" }}
      >
        <div style={{ backgroundColor: "#1c1e23", padding: "16px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button onClick={handleCloseLanguage}>
              <FiArrowLeft style={{ color: "white" }} />
            </Button>
            <div style={{ flex: 7, color: "white" }} className="text-font4">
              {intl.formatMessage({ id: "choose_language" })}
            </div>
            <div style={{ flex: 2 }}></div>
          </div>

          <div
            style={{
              color: "white",
              cursor: "pointer",
              width: "100%",
              paddingTop: "20px",
            }}
            className="text-font3"
          >
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("zh-cn")}
            >
              <img
                src={toAbsoluteUrl("/image/homePage/cn.png")}
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ marginLeft: "20px" }}>
                {intl.formatMessage({ id: "mandarin" })}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                cursor: "pointer",
              }}
              onClick={() => handleLanguageChange("en")}
            >
              <img
                src={toAbsoluteUrl("/image/homePage/en.png")}
                style={{ width: "25px", height: "25px" }}
              />
              <div style={{ marginLeft: "20px" }}>
                {intl.formatMessage({ id: "english" })}
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
